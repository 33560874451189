import { createApp } from 'vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'

const app = createApp(App)


app.use(router).use(Vant).use(ElementPlus).mount('#app')